import { Component, Inject, OnInit, ViewChild, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { tbAcompanhamento } from 'src/app/entities/tbAcompanhamento';
import { tbAcompanhamentoContinuado } from 'src/app/entities/tbAcompanhamentoContinuado';
import { TbAgenda } from 'src/app/entities/tbAgenda';
import { TbContratado } from 'src/app/entities/tbContratado';
import { TbInstituicao } from 'src/app/entities/tbInstituicao';
import { TbSetor } from 'src/app/entities/tbSetor';
import { TbTema } from 'src/app/entities/tbTema';
import { TbTopico } from 'src/app/entities/tbTopico';
import { VwContratadoFuncaoCargo } from 'src/app/entities/vwContratadoFuncaoCargo';
import { AcompanhamentoContinuadoWebapiService } from 'src/app/shared/services/webapi/acompanhamento-continuado-webapi.service';
import { AgendaWebapiService } from 'src/app/shared/services/webapi/agenda-webapi.service';
import { ContratadoWebapiService } from 'src/app/shared/services/webapi/contratado-webapi.service';
import { InstituicaoWebapiService } from 'src/app/shared/services/webapi/instituicao-webapi.service';
import { TemaWebapiService } from 'src/app/shared/services/webapi/tema-webapi.service';
import { TopicoWebapiService } from 'src/app/shared/services/webapi/topico-webapi.service';

@Component({
  selector: 'app-aplicar',
  templateUrl: './aplicar.component.html',
  styleUrls: ['./aplicar.component.css']
})
export class AplicarComponent implements OnInit {
  @ViewChild('ngForm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };

  public tbSetores: TbSetor[] = new Array<TbSetor>();
  public tbSetor: TbSetor = new TbSetor();
  public tbTemas: TbTema[] = new Array<TbTema>();
  public tbTema: TbTema = new TbTema();
  public tbTopicos: TbTopico[] = new Array<TbTopico>();
  public listaPresenca: VwContratadoFuncaoCargo[] = new Array<VwContratadoFuncaoCargo>();
  public contratadoPresente: VwContratadoFuncaoCargo = new VwContratadoFuncaoCargo();
  public vwContratadoFuncaoCargo: VwContratadoFuncaoCargo = new VwContratadoFuncaoCargo();
  public tbTopico: TbTopico = new TbTopico();
  public tbAgenda: TbAgenda = new TbAgenda();
  public tbContratado: TbContratado = new TbContratado();
  public tbAcompanhamentoContinuado: tbAcompanhamentoContinuado = new tbAcompanhamentoContinuado();

  TopicoTexto: number = 0;
  desabilitarConcluido: boolean = true;
  ocultarConcluido: boolean = false;
  desabilitarBotaoRegistro: boolean = true;
  public idInstituicao;
  public DadosRegistro;
  public listaPresente = new Array();
  codeInstituicao = '';
  HFtkn = localStorage.getItem("HFtkn");

  constructor(@Inject(forwardRef(() => AppComponent))
  public parent: AppComponent,
    public toast: ToastrService,
    private activatedRoute: ActivatedRoute,
    private instituicaoWebApiService: InstituicaoWebapiService,
    private temaWebapiService: TemaWebapiService,
    private topicoWebapiService: TopicoWebapiService,
    private acompanhamentoContinuadoService: AcompanhamentoContinuadoWebapiService,
    private contratadoWebApiservice: ContratadoWebapiService,
    private agendaWebapiService: AgendaWebapiService) {
    this.codeInstituicao = localStorage.getItem("chaveId");
    this.BuscarInstituicaoPorCode();
    this.DadosRegistro = JSON.parse(localStorage.getItem("agenda"));
  }

  countCheckedTopicos(checked) {
    console.log(checked);
    if (checked.currentTarget.checked == true)
      this.TopicoTexto--;
    else
      this.TopicoTexto++;

    if (this.TopicoTexto == 0 && this.listaPresente.length > 0) {
      this.desabilitarConcluido = false;
    }
    else {
      this.desabilitarConcluido = true;
      this.desabilitarBotaoRegistro = true;
    }
  }

  presencaChecked(checked, nomeContratado) {
    if (checked.currentTarget.checked == true)
      this.listaPresente.push(nomeContratado);
    else
      this.listaPresente = this.listaPresente.filter(x => x !== nomeContratado);
  }

  salvarPresenca() {
    this.listaPresente.forEach(nomeContratado => {
      this.contratadoPresente = this.listaPresenca.find(x => x.nomeContratado == nomeContratado);
      this.contratadoWebApiservice.SelecionarContratadoPorNome(this.idInstituicao, nomeContratado, this.HFtkn, (data: VwContratadoFuncaoCargo) => {
        this.vwContratadoFuncaoCargo = data[0];

        this.tbAcompanhamentoContinuado.concluido = true;
        this.tbAcompanhamentoContinuado.excluido = false;
        this.tbAcompanhamentoContinuado.idAgenda = this.DadosRegistro.id;
        this.tbAcompanhamentoContinuado.idContratado = this.vwContratadoFuncaoCargo.idContratado;
        this.tbAcompanhamentoContinuado.idInstituicao = this.idInstituicao;

        this.acompanhamentoContinuadoService.incluir(this.tbAcompanhamentoContinuado, this.HFtkn, (data: tbAcompanhamentoContinuado) => {
        },
          (error) => {
            this.toast.error(error);
          });
      },
        (error) => {
          this.toast.error(error);
        });
    });
  }

  BuscarInstituicaoPorCode() {
    this.instituicaoWebApiService.pesquisarInstituicaoPorCode(this.codeInstituicao, this.HFtkn, (data: TbInstituicao) => {
      this.idInstituicao = data[0];
      this.listarTemas();
      
      this.BuscarContratadosPorNomeSetor(this.DadosRegistro.nomeSetor,);

      this.activatedRoute.queryParams.subscribe(
        (queryParams: any) => {
          const idTema = queryParams['idTema'];
          if (idTema != undefined) {
            this.tbTema.id = Number(idTema);
            this.ListarTopicosPorTema();
          }
        }
      );
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }


  habilitarRegistro() {
    this.agendaWebapiService.selecionarPorId(this.DadosRegistro.id, this.HFtkn, (data: TbAgenda) => {
      this.tbAgenda = data;
      this.tbAgenda.dataRealizada = this.tbAgenda.dataPlanejada;
      this.DadosRegistro.dataRealizada = this.tbAgenda.dataRealizada.toISOString();
      localStorage.removeItem("agenda");
      let ArrayAgenda = JSON.stringify(this.DadosRegistro);
      localStorage.setItem("agenda", ArrayAgenda);
      this.agendaWebapiService.alterar(this.tbAgenda, this.HFtkn, () => {
        this.desabilitarBotaoRegistro = false;
        this.salvarPresenca();
        this.ocultarConcluido = true;
      });
    },
      (error) => {
        this.toast.error(error);
      });
  }

  ListarTopicosPorTema() { // o primeiro paramentro é 1 para pegar os topicos padrao do sistema habilita
    this.topicoWebapiService.TopicosPorTema(this.idInstituicao, this.tbTema.id, this.HFtkn, (dataTopico: TbTopico[]) => {
      this.tbTopicos = dataTopico;
      this.TopicoTexto = this.tbTopicos.length;
      this.desabilitarConcluido = true;
      this.desabilitarBotaoRegistro = true;

    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarTemas() {
    this.temaWebapiService.selecionarPorIdInstituicao(1, this.HFtkn, (dataTema: TbTema[]) => {
      this.tbTemas = dataTema;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarTemasPorId(id) {
    this.temaWebapiService.selecionarPorId(id, this.HFtkn, (dataTema: TbTema[]) => {
      this.tbTemas = dataTema;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  BuscarContratadosPorNomeSetor(nomeSetorGrupo) {

    this.contratadoWebApiservice.ListarContratadosPorNomeSetor(this.idInstituicao, nomeSetorGrupo, this.DadosRegistro.tipo, this.HFtkn, (data: VwContratadoFuncaoCargo[]) => {
      this.listaPresenca = data;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  ngOnInit(): void {
  }

}